


































import { Component, Mixins } from 'vue-property-decorator';

import SelectApp from '@/components/Input/Select/Select.vue';
import Loading from '@/components/Loading/Loading.vue';

import OPTIONS_LANGUAGES from '../../constants/LanguagesOptions';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { DEFAULT_COPY_MODAL_PLAN_UPDATE } from '@/constant/Modal';
import {
  SIMULADO_REVISAO,
  SIMULADO_VESTIBULARES_ANTERIORES,
  SIMULADO_CURSOS,
} from '@/constant/Simulated';

import ActiveModal from '@/share/Util/ActiveModal';

import permission from '@/mixins/permission';

const FIRST_DAY_SIMULATED = 0;
const ENGLISH_QUESTIONS = 'Língua Inglesa';
const SPANISH_QUESTIONS = 'Língua Espanhola';

@Component({
  components: {
    SelectApp,
    Loading,
  },
})
export default class ContainerButtonsInstruction extends Mixins(permission) {
  private isLoadingButton = false;
  private idLanguageSelected = '';
  private optionsLanguages = OPTIONS_LANGUAGES;

  private setModal = new ActiveModal();

  beforeDestroy() {
    this.$store.commit('setModalPlanUpdate', DEFAULT_COPY_MODAL_PLAN_UPDATE);
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get hasPermission() {
    const simulationUrl = this.$store.getters.simulatedTitleCover.url || '';

    if (this.isPlanFree) {
      return false;
    }

    if (simulationUrl.includes(SIMULADO_VESTIBULARES_ANTERIORES)) {
      return this.can(LIST_PERMISSION.SIMULATIONS_PREVIOUS);
    }

    if (simulationUrl.includes(SIMULADO_REVISAO)) {
      return this.can(LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM);
    }

    if (simulationUrl.includes(SIMULADO_CURSOS)) {
      return this.can(LIST_PERMISSION.SIMULATIONS_COURSES);
    }

    return false;
  }

  get simulatedDayHasLinguages() {
    if (this.$store.getters.infoSimulatedQuestion?.results.length) {
      const simulatedQuestions = this.$store.getters.infoSimulatedQuestion?.results[0]?.questions;
      return simulatedQuestions[ENGLISH_QUESTIONS] && simulatedQuestions[SPANISH_QUESTIONS];
    }

    return false;
  }

  get showLanguageSelection() {
    const { indexSimulatedDay } = this.$store.getters;

    return indexSimulatedDay === FIRST_DAY_SIMULATED && this.simulatedDayHasLinguages;
  }

  setSelectedOption(selectedOption: string) {
    this.idLanguageSelected = selectedOption;
  }

  handleStartSimulated() {
    if (!this.hasPermission) {
      this.setShowModal();
      return;
    }

    const canStart = (this.showLanguageSelection && this.idLanguageSelected) || !this.showLanguageSelection;

    if (canStart) {
      this.redirectToSimulatedQuestions();
      return;
    }
    this.$emit('show-modal');
  }

  setShowModal() {
    if (this.isPlanFree) this.showModalPlanUpdate();
    else this.showModalPermission();
  }

  showModalPlanUpdate() {
    this.$store.commit('setModalPlanUpdate', {
      title: 'Você precisa ser aluno premium para fazer um simulado.',
      description: 'Por menos de R$1,40 por dia, você pode ter acesso ilimitado a tudo que o Explicaê oferece.',
    });

    this.setModal.activeModal('modalPlanUpdate');
  }

  showModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  redirectToSimulatedQuestions() {
    this.isLoadingButton = true;
    this.$store.commit('saveIdLanguageSelected', this.idLanguageSelected);

    this.$router.push({
      name: 'SimulatedQuestions',
      params: {
        id: this.$route.params.id,
      },
    });

    this.$store.commit('setPermanenceSimulatedData', true);
  }
}
