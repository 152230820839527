





































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Instruction from '@/components/Instruction/Instruction.vue';
import InstructionLoading from '@/components/Instruction/InstructionLoading.vue';
import Footer from '@/components/Footer/Footer.vue';

import ContainerButtonsInstruction from './components/ContainerButtonsInstruction/index.vue';
import ContainerButtonsInstructionLoading from './components/ContainerButtonsInstruction/ContainerButtonsInstructionLoading.vue';
import ModalSelectLanguage from './components/Modals/ModalSelectLanguage.vue';

import ConfigSidebar from './mixins/ConfigSidebar';

import ActiveModal from '@/share/Util/ActiveModal';

import LIST_INSTRUCTIONS from './constants/ListInstructionSimulated';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Instruction,
    InstructionLoading,
    ModalSelectLanguage,
    ContainerButtonsInstruction,
    ContainerButtonsInstructionLoading,
    Footer,
  },
  mixins: [ConfigSidebar],
})
export default class SimulatedQuestion extends Vue {
  private listInstruction = LIST_INSTRUCTIONS

  private activeModal = new ActiveModal();

  mounted() {
    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setSidebarExistence', false);
    this.$store.commit('setRedactionSubmissionSettings', {
      component: '',
      time: null,
    });
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get titleSimulated() {
    return this.$store.getters.simulatedTitleCover.title || '';
  }

  get isLoadingQuestionsSimulated() {
    return this.$store.getters.isLoadingQuestionsSimulated;
  }

  showModalSelectLanguage() {
    this.activeModal.activeModal('ModalSelectLanguage');
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: `Simulado${this.titleSimulated ? 's' : ''}`, to: '/' },
      { title: this.titleSimulated, to: null },
    ]);
  }
}
